body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
}

header {
  background-color: white;
  color: black;
  margin: 1px;
  font-size: 4vmin;
}

footer {
  background-color: black;
  color: antiquewhite;
  -webkit-text-size-adjust: 1px;
     -moz-text-size-adjust: 1px;
          text-size-adjust: 1px;
  position: -webkit-sticky;
  position: sticky;
  margin: 1vh;
  top: 100vh;
  align-self:center;
}

.container {
  color: black; 
  background-color: rgba(250, 250, 45, 0.783);
  border-radius: 30px 30px;
  padding: 2%;
}

.buttonTemperature {
  background-color: #36A9AE;
  background-image: linear-gradient(#37ADB2, #329CA0);
  border: 1px solid #2A8387;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 1px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin: 3px;
  outline: 0;
  padding: 5px;
  text-align: center;
  transition: box-shadow .05s ease-in-out,opacity .05s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 25%;
}

.buttonTemperature:hover {
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 2px inset, rgba(0, 0, 0, 0.4) 0 1px 2px;
  text-decoration: none;
  transition-duration: .15s, .15s;
}

.buttonTemperature:active {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 4px inset, rgba(0, 0, 0, 0.4) 0 1px 1px;
}

.buttonTemperature:disabled {
  cursor: not-allowed;
  opacity: .6;
}

.buttonTemperature:disabled:active {
  pointer-events: none;
}

.buttonTemperature:disabled:hover {
  box-shadow: none;
}

a {
  color:aqua;
}
